import dayjs from "dayjs";
import { getWeddingDate } from "../../lib/weddingWebsite";

export function formattedDateTime(date) {
  const dayjsTime = dayjs.unix(date.seconds);
  const formattedTime = dayjsTime.format("hh:mm A");
  const formattedDate = dayjsTime.format("MM/DD/YY");
  return { formattedTime, formattedDate };
}

export function formatEventDateTime(startDate, endDate) {
  if (!startDate) return { date: "", startTime: "", endTime: "" };

  const { formattedDate, formattedTime: startTime } =
    formattedDateTime(startDate);
  const endTime = endDate ? formattedDateTime(endDate).formattedTime : "";

  return { date: formattedDate, startTime, endTime };
}

export function formatLocation(state, city) {
  return state && city ? `${city}, ${state}` : "";
}

export async function getHomeData(user) {
  const receptionEvent = user?.MainEventDetails?.MyEvents?.find(
    e => e.eventName === "Reception"
  );

  const homeData = {
    eventName: receptionEvent?.eventName ?? "",
    ...formatEventDateTime(receptionEvent?.eventDate, receptionEvent?.toDate),
    firstName: user?.firstName ?? "",
    lastName: user?.lastName ?? "",
    partnerFirstName: user?.partnerFirstName ?? "",
    partnerLastName: user?.partnerLastName ?? "",
    weddingDate: getWeddingDate(receptionEvent?.eventDate) ?? null,
    weddingLocation: formatLocation(
      receptionEvent?.state,
      receptionEvent?.city
    ),
  };
  return homeData;
}

export function getCustomPageName(home) {
  const { firstName = "", partnerFirstName = "" } = home ?? {};

  if (!firstName && !partnerFirstName) return "";

  const names = [firstName, partnerFirstName].filter(Boolean);
  return names.join("-").replace(/\s+/g, "-").toLowerCase();
}
