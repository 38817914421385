import secureLocalStorage from "react-secure-storage";

export function getItem(key, defaultValue = null) {
  return secureLocalStorage.getItem(key) ?? defaultValue;
}

export function setItem(key, value) {
  secureLocalStorage.setItem(key, value);
}

export function removeItem(key) {
  secureLocalStorage.removeItem(key);
}

export const secureStorage = {
  getItem,
  setItem,
  removeItem,
};
