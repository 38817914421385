import dayjs from "dayjs";
import moment from "moment";

import { Messages } from "../config/constants";
import { updateMainEventsDetails } from "../models/MainEvents/mainEvents";
import { getState } from "../models/states/states";
import { post } from "../req";

const apiUrl = process.env.NEXT_PUBLIC_API_URL;

export const makeOptions = (options, value, label, disable = false) =>
  options.map(option => {
    return disable
      ? { value: option[value], label: option[label], isDisabled: false }
      : { value: option[value], label: option[label] };
  });

export async function validateZipcode({ stateId, cityName, zipCode }) {
  if (!stateId || !cityName || !zipCode) {
    return false;
  }

  const stateCode = (await getState(stateId))?.abbreviation;

  const r = await post(`${apiUrl}/open/zipcode/verify`, {
    stateCode,
    cityName,
    zipCode,
  });
  return r?.valid;
}

export function uniqueData(arr) {
  return [...new Set(arr.map(JSON.stringify))].map(JSON.parse);
}

export function compareString(s1, s2) {
  if (typeof s1 !== "string" || typeof s2 !== "string") return false;

  const equalStrings = s1.toLowerCase() === s2.toLowerCase();

  if (equalStrings) {
    return true;
  }

  const substring1 = s1.substring(0, 3).toLowerCase();
  const substring2 = s2.substring(0, 3).toLowerCase();
  return substring1 === substring2;
}

function getFilterByStateIdArray({
  filterCities,
  fStateId,
  fZipCode,
  fCity,
  dispatch,
  cityList,
}) {
  let filterByStateId = [];

  if (filterCities.length && (fStateId || fZipCode || fCity)) {
    const {
      stateId = "",
      stateName = "",
      id = "",
      name = "",
    } = filterCities[0];

    if (filterCities.length === 1) {
      if (!fStateId) {
        dispatch({
          type: "state",
          value: { value: stateId, label: stateName },
        });
      }
      if (!fCity) {
        dispatch({ type: "city", value: { value: id, label: name } });
      }
    }

    if (stateId) {
      filterByStateId = [...cityList]
        .filter(i => compareString(i.stateId, stateId))
        .sort((a, b) => a.label.localeCompare(b.label));
    }
  } else {
    filterByStateId = [...cityList].filter(i =>
      compareString(i.stateId, fStateId)
    );
  }

  return filterByStateId;
}

export function filterCities({
  fStateId,
  fCity,
  fZipCode,
  dispatch,
  cityList,
  cityFirst = false,
}) {
  let filteredCitiesList = cityList;

  // Filter by state ID
  if (fStateId) {
    filteredCitiesList = filteredCitiesList.filter(i => compareString(i.stateId, fStateId));
  }

  // Filter by city name if `fCity`
  filteredCitiesList = filteredCitiesList.filter(i => compareString(i.name, fCity));

  // Filter by zip code
  if (fZipCode) {
    filteredCitiesList = filteredCitiesList.filter(c =>
      c.zipcodes?.some(zip => compareString(zip, fZipCode))
    );
  }

  // Map to desired structure and sort by city name
  filteredCitiesList = filteredCitiesList
    .map(c => ({ ...c, label: c.name, value: c.id }))
    .sort((a, b) => a.label.localeCompare(b.label));

  if (cityFirst) {
    return getUniqueCities(filteredCitiesList);
  }

  const filterByStateId = getFilterByStateIdArray({
    filterCities: filteredCitiesList,
    fStateId,
    fZipCode,
    fCity,
    dispatch,
    cityList,
  });

  return filterByStateId;
}

export function filterZipCodes({ citiesOptions, fStateId, fCity }) {
  if (!fCity && !fStateId) return [];

  let tmpCities = [...citiesOptions];
  if (fCity) {
    tmpCities = tmpCities.filter(
      i => i.name?.toLowerCase() === fCity?.toLowerCase()
    );
  }

  tmpCities = tmpCities
    .flatMap(i => i?.zipcodes ?? [])
    .sort((a, b) => a.localeCompare(b));

  return Array.from(new Set(tmpCities)).map(i => ({ value: i, label: i }));
}

export const handleKeyDown = (e, fieldName, formData, dispatch) => {
  const zipCodeRegex = /^\d{0,5}$/;
  const cityRegex = /^[a-zA-Z ]*$/;

  let regex = fieldName === "zipCode" ? zipCodeRegex : cityRegex;

  let currentText = formData?.[fieldName] || "";
  if (e.key === "Backspace" && currentText.length > 0) {
    currentText = currentText.substring(0, currentText.length - 1);
  } else if (e.key !== "Backspace" && e.key.match(regex)) {
    currentText = currentText.concat(e.key);
  }
  if (fieldName === "city") {
    dispatch({ type: "state", value: { value: "", label: "" } });
    dispatch({ type: "zipCode", value: { value: "", label: "" } });
  }

  dispatch({
    type: fieldName,
    value: { value: currentText, label: currentText },
  });
};

function getUniqueCities(cityList) {
  const data = {};

  for (const city of cityList) {
    const cityName = city.label;

    if (!data[cityName]) {
      data[cityName] = { ...city };
    }
  }

  return Object.values(data);
}

export function blankEvent() {
  // intentionally left blank to fix sonar issue
}

export function getCityAndStateInfo(eventData) {
  return {
    state: eventData?.state || "",
    stateId: eventData?.stateId || "",
    city: eventData?.city || "",
    cityId: eventData?.cityId || "",
  };
}

export function isValidDate(current) {
  const minDay = moment().subtract(1, "day");
  const maxDay = moment().add(24, "months");
  return current.isAfter(minDay) && current.isBefore(maxDay);
}
export const getZipcode = details => {
  if (details?.zipCode?.label) {
    return details?.zipCode?.label;
  } else if (details?.zipCode) {
    return details?.zipCode;
  } else {
    return "";
  }
};

export const getContactAddress2 = details => {
  if (details?.contactAddress2) {
    return details?.contactAddress2 + ", ";
  } else {
    return "";
  }
};

export const removeCommonObjects = (array1, array2, propertyName) => {
  const uniqueArray1 = array1.filter(
    obj1 => !array2.some(obj2 => obj2[propertyName] === obj1[propertyName])
  );
  const uniqueArray2 = array2.filter(
    obj2 => !array1.some(obj1 => obj1[propertyName] === obj2[propertyName])
  );

  return [uniqueArray1, uniqueArray2];
};

export const disableOptions = (options, value, label, disable = true) =>
  options.map(option => {
    return disable
      ? { value: option[value], label: option[label], isDisabled: true }
      : { value: option[value], label: option[label] };
  });

export const eventOptions = [
  { value: 1, label: "1" },
  { value: 2, label: "2" },
  { value: 3, label: "3" },
  { value: 4, label: "4" },
  { value: 5, label: "5" },
  { value: 6, label: "6" },
];

export const isProductionEnv = process.env.NEXT_PUBLIC_ENV === "prod-clone";

export function VMAIntegration(category) {
  const VMAs = ["baraat", "baarat", "photography", "cinematography"];

  if (!isProductionEnv) {
    VMAs.push("catering");
    VMAs.push("beauty");
    VMAs.push("floral & decor");
    VMAs.push("dj & entertainment");
  }

  return VMAs.includes(category?.categoryName?.toLowerCase());
}

export const capitalizeFirstLetter = str => {
  if (str === undefined) return "";
  return str?.charAt(0).toUpperCase() + str?.slice(1);
};

export const textToDisplay = ({
  hospitality,
  logistics,
  aesthetics,
  experience,
  name,
  expTotalCount,
  aesTotalCount,
  hosTotalCount,
  logTotalCount,
  expOneMonthCount,
  logOneMonthCount,
  hosOneMonthCount,
  aesOneMonthCount,
}) => {
  if (
    hospitality === hosTotalCount &&
    logistics === logTotalCount &&
    aesthetics === aesTotalCount &&
    experience === expTotalCount
  ) {
    return (
      <div className="to-do-animation-head">
        <h1>
          Well done, {capitalizeFirstLetter(name)}! Your rangoli is complete.
        </h1>
        <h6>Cheers to the beautiful celebration ahead!</h6>
      </div>
    );
  } else if (
    hospitality >= hosOneMonthCount &&
    logistics >= logOneMonthCount &&
    aesthetics >= aesOneMonthCount &&
    experience >= expOneMonthCount
  ) {
    return (
      <div className="to-do-animation-head">
        <h1>
          Great Job {capitalizeFirstLetter(name)}, you&apos;re almost there!
        </h1>
        <h6>Let&apos;s Complete your Rangoli of Romance!</h6>
      </div>
    );
  } else if (
    hospitality < hosOneMonthCount ||
    logistics < logOneMonthCount ||
    aesthetics < aesOneMonthCount ||
    experience < expOneMonthCount
  ) {
    return (
      <div className="to-do-animation-head">
        <h1>Hi {capitalizeFirstLetter(name)}, Let&apos;s make magic happen!</h1>
        <h6>Complete tasks and watch your rangoli come to life!</h6>
      </div>
    );
  }
};
const fetchChristianFlagCount = (flagType, setFlagCount) => {
  if (flagType === "Logistics") {
    setFlagCount(7);
  } else if (flagType === "Hospitality") {
    setFlagCount(7);
  } else if (flagType === "Experience") {
    setFlagCount(2);
  } else if (flagType === "Aesthetics") {
    setFlagCount(1);
  } else {
    setFlagCount(0);
  }
};
const fetchHinduFlagCount = (flagType, setFlagCount) => {
  if (flagType === "Logistics") {
    setFlagCount(7);
  } else if (flagType === "Hospitality") {
    setFlagCount(6);
  } else if (flagType === "Aesthetics") {
    setFlagCount(1);
  } else if (flagType === "Experience") {
    setFlagCount(2);
  } else {
    setFlagCount(0);
  }
};
export const fetchOneMonthFlagCount = (religion, flagType, setFlagCount) => {
  if (religion === "Christian") {
    fetchChristianFlagCount(flagType, setFlagCount);
  } else if (religion === "Hindu") {
    fetchHinduFlagCount(flagType, setFlagCount);
  }
};

const garlandAnimations = {
  Logistics: "logistics-garland active",
  Hospitality: "hospitality-garland active",
  Experience: "experience-garland active",
  Aesthetics: "aesthetics-garland active",
};

export const checkGarland = async (todo, user) => {
  let garlandAnimation;
  if (todo?.type === "common") {
    garlandAnimation = user?.MainEventDetails?.garlandOneMonthAnimation
      ? ""
      : "onemonth-garland active";
  } else {
    garlandAnimation = garlandAnimations[todo?.flag];
  }

  if (garlandAnimation) {
    await updateMainEventsDetails({ garlandAnimation }, user.MainEventId);
  }
};

export const emptyHandler = _e => {
  return null;
};

export const formatedDate = data => {
  const date = new Date(data);
  const formattedDate = `${("0" + (date.getMonth() + 1)).slice(-2)}-${(
    "0" + date.getDate()
  ).slice(-2)}-${date.getFullYear()}`;
  return formattedDate;
};

export const weddingDaysMessage = (days, religion) => {
  const weddingCompletedMessages = {
    Christian: Messages.christianWeddingCompleted,
    Hindu: Messages.hinduWeddingCompleted,
    Sikh: Messages.sikhWeddingCompleted,
    Muslim: Messages.muslimWeddingCompleted,
  };

  if (days < 0) {
    return weddingCompletedMessages[religion] || "";
  }

  return `${days} Days to go!`;
};

/**
 * Returns a list of todos that are due till the current week.
 * @param {Array} list - The list of todos to filter.
 * @returns {Array} The filtered list of todos that are due in the current week.
 */
export const getTodosUntilCurrentWeek = list => {
  const currentWeekEnd = dayjs().endOf("week").endOf("day");
  const filteredTodoList = list?.pending?.filter(todo => {
    const dueDate = dayjs(todo?.dueDate?.toDate());
    return (
      dueDate.isSame(dayjs(todo?.dueDate?.toDate()), "day") &&
      dueDate.isBefore(currentWeekEnd)
    );
  });

  // Return the filtered list of todos that are due in the current week.
  return filteredTodoList;
};

export function handleFileUpload(files, accept, onFileUpload) {
  const allFiles = Array.from(files);
  onFileUpload(allFiles);
}

export function getImageSrc(image) {
  if (!image) return null;
  return typeof image === "string" ? image : URL.createObjectURL(image);
}

export const handleCropComplete = (
  croppedImage,
  onImageUpload,
  setSelectedImage,
  setShowImagePopup
) => {
  if (!croppedImage) return;

  try {
    onImageUpload(croppedImage.blob);
    setSelectedImage(croppedImage.url);
    setShowImagePopup(false);
  } catch (error) {
    console.error("Failed to handle cropped image:", error);
  }
};
export const handleFileSelect = (
  event,
  setSelectedImage,
  setShowImagePopup
) => {
  const file = event[0];
  if (!file) return;
  const reader = new FileReader();
  reader.onload = e => {
    setSelectedImage(e.target.result);
    setShowImagePopup(true);
  };
  reader.readAsDataURL(file);
};

export function formatPrice(price) {
  if (price === undefined || isNaN(price)) {
    return "$0.00";
  }

  return new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  }).format(price);
}

export function exctractContentFromHtml(value)
{
 return new DOMParser().parseFromString(
    `<body>${value}</body>`,
    "text/html"
).body.textContent
}

export function showToast(setToast, type, message) {
  setToast({ type, message });
  setTimeout(function clearToast() {
    setToast({ type: "", message: "" });
  }, 10);
}